<template>
  <div
    :id="`${modalId}`"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
    <div
      class="modal-dialog sx-modal-responsive"
      style="width: 90% !important; min-width: 90% !important"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="designedItems.length">
            <i class="fas fa-plus"></i>&nbsp;
            {{
              this.$t(
                "Set.NewFormat",
                {},
                { locale: this.$store.state.activeLang }
              ).replaceAll("OBJECT_NAME", this.pageData.name)
            }}
          </h5>
          <h5 v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" :class="{ 'p-0': designedItems.length }">
          <SetLoader
            :isDocumentTitleChange="false"
            v-if="!designedItems.length"
          />
          <form class="form w-100" id="treeViewForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <AlertBox type="warning" :messages="formError" />
                  <ValidationMessageList
                    v-if="insertResponseValidationModel.showValidations"
                    :isModal="false"
                    :msg="insertResponseValidationModel.msg"
                    :message="insertResponseValidationModel.message"
                    :errors="insertResponseValidationModel.errors"
                    :warnings="insertResponseValidationModel.warnings"
                    :informations="insertResponseValidationModel.informations"
                  />
                  <ValidationMessageListInModal
                    :windowOpener="windowOpener"
                    :errors="insertResponseValidationModel.modalErrors"
                    :warnings="insertResponseValidationModel.modalWarnings"
                    :informations="
                      insertResponseValidationModel.modalInformations
                    "
                    :businessRuleErrors="
                      insertResponseValidationModel.businessRuleErrors
                    "
                  />
                </div>
              </div>
            </div>
            <HiddenFields crudType="Tree" :pageData="pageData" />
            <DesignItem
              v-for="element in designedItems"
              :key="element.id"
              :element="element"
              :items="designedItems"
            />
          </form>
        </div>
        <div class="modal-footer">
          <template v-if="designedItems.length">
            <button
              @click="save('btn-tree-record-save')"
              type="button"
              class="btn btn-success btn-tree-record-save me-2"
            >
              <span>
                <i class="fa fa-save"></i>
                {{
                  $t(
                    "BaseModelFields.Save",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</span
              >
            </button>
          </template>
          <button
            type="button"
            class="btn btn-danger btn-sm"
            data-bs-dismiss="modal"
          >
            <i class="fas fa-times"></i>
            {{
              $t(
                "BaseModelFields.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationMessageList from "@/components/custom/validation-message/List.vue";
import ValidationMessageListInModal from "@/components/custom/validation-message/Modal.vue";

import { hideModal, showModal } from "@/core/helpers/dom";
import $ from "jquery";
import { createToast } from "mosha-vue-toastify";
import HiddenFields from "@/components/set-design/form-items/HiddenFields.vue";
import DesignItem from "@/components/set-design/DesignItem.vue";
export default {
  name: "TreeNewRecordModal",
  props: ["pageData", "designedItems"],
  emits: ["recordCreated"],
  watch: {
    pageData() {
      this.formError = [];
      this.insertResponseValidationModel = {};
      $("form#treeViewForm").removeClass("was-validated");
    },
  },
  data() {
    return {
      modalId: "treeNewRecordModal",
      model: {},
      formError: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-tree-record-save",
      insertResponseValidationModel: {},
    };
  },
  components: {
    DesignItem,
    HiddenFields,
    ValidationMessageList,
    ValidationMessageListInModal,
  },
  methods: {
    save(buttonName) {
      var form = $("form#treeViewForm"),
        allFields = this.pageData.fields.find(
          (f) => f.isMainObject
        ).fieldModels;

      this.model = this.$root.modelCreate("form#treeViewForm");

      this.insertResponseValidationModel = {
        showValidations: false,
        msg: "",
        message: "",
        errors: [],
        modalErrors: [],
        warnings: [],
        modalWarnings: [],
        informations: [],
        modalInformations: [],
        businessRuleErrors: [],
      };

      if (!form[0].checkValidity() || !this.$root.forceFormValidation(form)) {
        form.addClass("was-validated");

        this.$root.elementScrollToTop($(`#${this.modalId}`));
        this.insertResponseValidationModel.showValidations = true;
        this.insertResponseValidationModel.errors = this.$root.formValidation(
          form,
          this.model.values,
          allFields
        );

        return;
      }

      var button = $(`.${buttonName}`),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses),
        self = this;

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.formError = [];
      this.model.values = this.model.values.map(function (m) {
        return { key: m.key, value: m.value };
      });
      this.$appAxios
        .post("/rws-SetController-New", this.model)
        .then((response) => {
          var result = response.data,
            brErrors = result.businessRuleResponse.businessRuleResponses,
            informations = result.validations.filter(function (f) {
              return f.validationType.value == 3;
            });

          this.insertResponseValidationModel.businessRuleErrors = brErrors;

          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          form.removeClass("was-validated");

          if (result.isOk) {
            createToast(
              this.$t(
                "BaseModelFields.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );

            this.$emit("recordCreated", result);

            if (
              this.insertResponseValidationModel.businessRuleErrors.length == 0
            ) {
              hideModal(document.getElementById(`${this.modalId}`));
            }
          } else {
            this.insertResponseValidationModel = {
              msg: result.msg,
              message: result.message,
              errors: this.$root.getValidationMessages(
                result.validations,
                false,
                1,
                allFields
              ),
              modalErrors: this.$root.getValidationMessages(
                result.validations,
                true,
                1,
                allFields
              ),
              warnings: this.$root.getValidationMessages(
                result.validations,
                false,
                2,
                allFields
              ),
              modalWarnings: this.$root.getValidationMessages(
                result.validations,
                true,
                2,
                allFields
              ),
              informations: this.$root.getValidationMessages(
                result.validations,
                false,
                3,
                allFields
              ),
              modalInformations: this.$root.getValidationMessages(
                result.validations,
                true,
                3,
                allFields
              ),
              businessRuleErrors: brErrors,
            };

            //unknown error
            if (
              String.isNullOrWhiteSpace(
                this.insertResponseValidationModel.msg
              ) &&
              String.isNullOrWhiteSpace(
                this.insertResponseValidationModel.message
              ) &&
              this.insertResponseValidationModel.errors.length == 0 &&
              this.insertResponseValidationModel.warnings.length == 0 &&
              this.insertResponseValidationModel.informations.length == 0 &&
              this.insertResponseValidationModel.modalErrors.length == 0 &&
              this.insertResponseValidationModel.modalWarnings.length == 0 &&
              this.insertResponseValidationModel.modalInformations.length == 0
            ) {
              this.insertResponseValidationModel.msg = this.$t(
                "BaseModelFields.AnUnknownErrorHasOccurred",
                {},
                { locale: this.$store.state.activeLang }
              );
            }

            this.insertResponseValidationModel.showValidations =
              !String.isNullOrWhiteSpace(
                this.insertResponseValidationModel.msg
              ) ||
              !String.isNullOrWhiteSpace(
                this.insertResponseValidationModel.message
              ) ||
              this.insertResponseValidationModel.errors.length > 0 ||
              this.insertResponseValidationModel.warnings.length > 0 ||
              this.insertResponseValidationModel.informations.length > 0;
          }

          var modalShow =
            this.insertResponseValidationModel.businessRuleErrors.length > 0 ||
            this.insertResponseValidationModel.modalErrors.length > 0 ||
            this.insertResponseValidationModel.modalWarnings.length > 0 ||
            this.insertResponseValidationModel.modalInformations.length > 0;
          if (modalShow) {
            showModal(document.getElementById("modalValidationMessage"));
          }
        })
        .catch(function (error) {
          // alert(error);
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          self.formError.push(error);
        });
    },
  },
};
</script>
